<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Перевозчик"
              >
                <el-select-clearable
                  v-model="filter.contractors"
                  class="form-control"
                  name="contractors"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  :remote-method="customersDriverDraftsContractorsList"
                  :loading="loading"
                  @input="updateFilter('contractors')"
                  @clear="customersDriverDraftsContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Типы"
              >
                <el-select
                  v-model="filter.type"
                  class="form-control"
                  name="type"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('type')"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Черновики
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="driver.fio"
            slot-scope="props"
          >{{ props.row.driver.surname+" "+props.row.driver.name }}<span v-if="props.row.driver.patronymic">{{ " "+props.row.driver.patronymic }}</span></span>
          <span
            slot="fio"
            slot-scope="props"
          >{{ props.row.surname+" "+props.row.name }}<span v-if="props.row.patronymic">{{ " "+props.row.patronymic }}</span></span>
          <span
            slot="updatedOn"
            slot-scope="props"
          >{{ formatDatetime(props.row.updatedOn) }}</span>
          <span
            slot="attorneys"
            slot-scope="props"
          >
            <span
              v-for="(attorney, index) in props.row.attorneys"
              :key="`attorney-${index}`"
            >
              <span><a :href="`/contractors/id${$store.state.user.ownerId}/attorney/id${attorney.id}`">№{{ attorney.number }}</a> {{ formatDate(attorney.dateStart) }} - {{ formatDate(attorney.dateEnd) }}</span><br>
              <p v-if="attorney.file">
                <a
                  :href="'/files/'+attorney.file.file.guid"
                  target="_blank"
                  @click.prevent="downloadFile(attorney.file.file)"
                >Скачать</a>
              </p>
            </span>
          </span>
          <span
            slot="type"
            slot-scope="props"
          >
            <span
              v-if="props.row.type === 0"
              class="text-secondary"
            >Добавление</span>
            <span
              v-if="props.row.type === 1"
              class="text-success"
            >Редактирование</span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 0"
              class="text-secondary"
            >Новый</span>
            <span
              v-if="props.row.status === 1"
              class="text-success"
            >Принят</span>
            <span
              v-else-if="props.row.status === 2"
              class="text-danger"
            >Отклонен</span>
            <!--span
              v-if="props.row.driver.blockedSecurity"
              class="badge badge-danger"
            >Блокировка СБ</span-->
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdminOrManager && props.row.status === 0"
              v-b-tooltip.hover.bottom="'Принять'"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
              @click="customersDriverDraftsAccept(props.row.id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`/driver-drafts/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="$store.getters.isAdminOrManager && props.row.status === 0"
              v-b-tooltip.hover.bottom="'Отклонить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="customersDriverDraftsDecline(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customersDriverDraftsContractorsList,
  customersDriverDrafts,
  customersDriverDraftsAccept,
  customersDriverDraftsDecline,
  downloadFile,
} from '../../services/api';
import moment from 'moment';
import {queryToUrl, URLToQuery} from '../../services/http';
import {arrayStringToNumber} from '../../components/mixins/helpers';
Vue.use(ServerTable);

export default {
  name: 'CustomerDriverDraftsList',
  components: {
    ServerTable,
    Event, ElSelectClearable
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      contractors: [],
      filter: {
        contractors: [],
        type: null,
        status: null,
      },
      typeOptions: [
        {value: null, text: 'Все'},
        {value: 0, text: 'Добавление'},
        {value: 1, text: 'Редактирование'},
      ],
      statusOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Принятые'},
        {value: 2, text: 'Отклоненные'},
        {value: 0, text: 'Новые'},
      ],
      loading: false,
      columns: [
        'id',
        'driver.fio',
        'fio',
        'contractor.name',
        'createdBy.username',
        'updatedOn',
        'attorneys',
        'type',
        'status',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [
          'contractors',
          'type',
          'status',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersDriverDrafts(Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'driver.fio': 'ФИО (оригинал)',
          'fio': 'ФИО (черновик)',
          'contractor.name': 'Перевозчик',
          'createdBy.username': 'Пользователь',
          'updatedOn': 'Дата',
          'attorneys': 'Доверенности',
          'type': 'Тип',
          'status': 'Статус',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  async mounted() {
    await this.customersDriverDraftsContractorsList();
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    downloadFile,
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    async customersDriverDraftsContractorsList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersDriverDraftsContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.contractors) {
        await this.customersDriverDraftsSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersDriverDraftsSelectedContractorsList() {
      this.loading = true;
      const selectedContractors = {};
      this.filter.contractors.map((item, index) => {
        selectedContractors['id[' + index + ']'] = item;
      });
      const response = await customersDriverDraftsContractorsList(this.$store.state.user.ownerId, selectedContractors);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.id);
          this.contractors.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersDriverDraftsAccept(driverDraftId) {
      this.loading = true;
      const response = await customersDriverDraftsAccept(driverDraftId);
      if (response && response.status === 200) {
        this.showSuccess('Черновик принят');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async customersDriverDraftsDecline(driverDraftId) {
      this.loading = true;
      const response = await customersDriverDraftsDecline(driverDraftId);
      if (response && response.status === 200) {
        this.showSuccess('Черновик отклонен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.contractors) {
        res.contractors = this.filter.contractors;
      }
      if (this.filter.type !== null) {
        res.type = this.filter.type;
      }
      if (this.filter.status !== null) {
        res.status = this.filter.status;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('contractors')) {
          params.contractors = arrayStringToNumber(params.contractors);
        }
        if (params.hasOwnProperty('type')) {
          params.type = Number(params.type);
        }
        if (params.hasOwnProperty('status')) {
          params.status = Number(params.status);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
